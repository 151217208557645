<template>
  <div class="revenueCenter">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">收益中心</div>
      <router-link tag="div" to="/withdrawalDetails" class="rightBtn"
        >提现明细</router-link
      >
    </div>
    <div class="walletBox">
      <div class="walletTitle">
        <div class="walletLine"></div>
        收益金币
      </div>
      <div class="bottomBox">
        <div class="amountBox">
          <div class="amount">
            <svg-icon class="goldenBeans" iconClass="goldenBeans1" />{{
              walletInfo.income + walletInfo.amount
            }}
          </div>
          <div class="withdrawalAmount">
            可提现余额 {{ walletInfo.income }} 元
          </div>
        </div>

        <div
          class="withdrawBtn"
          :class="{ activeBtn: walletInfo.income > 0 }"
          @click="jumpWithdraw"
        >
          收益提现
        </div>
      </div>
    </div>
    <div class="title">收益明细</div>
    <div class="revenueList">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="revenueItem" v-for="item in list" :key="item.id" @click="details(item)">
          <div class="userItem flex-center">
           <div class="avatar" @click.stop="jumpUserHome(item)">
            <ImgDecypt class="avatarImg" :src="item.cashierAvatar" round />
           </div>
           <div class="userInfo">
            <div class="userName">{{ item.cashierName }}</div>
            <div class="date">
             {{ item.createdAt | orderDate }}</div>
            <div class="userOpt">
             {{ item.desc }}
             <span class="postAmount">收益{{ item.actualAmount }}金币</span>
            </div>
           </div>
          </div>
          <div class="postInfo">
           <ImgDecypt class="postImg" :src="item.vidCover" />
<!--            <div class="postDesc ellipsis">{{ item.vidTitle }}</div>-->
<!--            <div class="postAmountBox">-->
<!--              <svg-icon class="goldIcon" iconClass="revenueGold" />-->
<!--            </div>-->
          </div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import { queryIncomes } from "@/api/mine";
import ImgDecypt from "@/components/ImgDecypt";
import PullRefresh from "@/components/PullRefresh";
export default {
  components: {
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
      walletInfo: {},
    };
  },
  created() {
    this.queryBalance();
    this.getList();
  },
  methods: {
   // 帖子详情
   details(item) {
    if (this.$route.path == '/postDetails') {
     return;
    }
    this.$router.push({
     path: "/postDetails",
     query: {
      videoID: item.productID
     },
    })
   },
   // 跳转用户主页
    jumpUserHome(item) {
     this.$router.push({
      path: "/userHomePage",
      query: {
       uid: item.cashierUID
      }
     })
    },
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 查询钱包
    async queryBalance() {
      await this.$store.dispatch("user/getWallet");
      this.walletInfo = this.$store.getters.walletInfo;
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(queryIncomes, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          list.forEach((i) => {
            i.isFollow = true;
          });
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.queryBalance();
      this.getList("refresh");
    },
    jumpWithdraw() {
      // if (this.walletInfo.income > 0) {
      //     this.$router.push("/withdraw")
      // }
      this.$router.push("/withdraw");
    },
  },
};
</script>

<style lang="scss" scoped>
.revenueCenter {
  height: 100%;
  color: #000;
  background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
      margin-right: 47px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 16px;
      color: #000;
    }

    .rightBtn {
      width: 64px;
      font-size: 14px;
      color: #999999;
    }
  }

  .walletBox {
    height: 125px;
    border-radius: 8px;
    margin: 12px 16px;
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;

    .walletTitle {
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #ff8f4c;
      margin: 13px 0 12px;
      .walletLine {
        width: 8px;
        height: 22px;
        border-radius: 0 6px 6px 0;
        margin-right: 12px;
        background: #ff8f4c;
      }

      span {
        font-size: 12px;
        color: #999999;
      }
    }

    .bottomBox {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0 12px 0 20px;
      box-sizing: border-box;
      .amountBox {
        .withdrawalAmount {
          color: #999;
          margin-left: 34px;
          margin-top: 3px;
          font-size: 12px;
        }
      }

      .amount {
        font-size: 30px;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: #ff8f4c;
        .goldenBeans {
          width: 32px;
          height: 32px;
          margin-right: 4px;
          margin-top: 4px;
        }
      }

      .withdrawBtn {
        width: 76px;
        height: 27px;
        border-radius: 27px;
        line-height: 27px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        background: #999999;
      }
      .activeBtn {
        color: #424242;
        background: #FBD13D;
      }
    }
  }

  .title {
    font-size: 18px;
    margin: 18px 12px;
  }

  .revenueList {
    padding: 0 12px;
    box-sizing: border-box;
    height: calc(100% - 217px);
    overflow-y: auto;

    .revenueItem {
      margin-bottom: 18px;
      padding-bottom: 18px;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      justify-content: space-between;
      .avatar {
       width: 40px;
       height: 40px;
       margin-right: 12px;
      }
      .userInfo {
        //width: 100%;
        display: flex;
        //align-items: flex-end;
        flex-direction: column;

        // justify-content: space-between;

        .userName {
          font-size: 14px;
          font-weight: 400;
          margin-right: 12px;
          color: rgba(102, 102, 102, 1);
        }
        .date {
         font-size: 12px;
         margin: 6px 0 6px 0;
         color: #999999;
        }

        .userOpt {
          font-size: 12px;
         .postAmount {
          font-size: 12px;
          color: #ff8f4c;
         }
        }
      }

      .postInfo {
        //margin: 3.5px 0 0 54px;
        //display: flex;
        //align-items: flex-end;
        //justify-content: space-between;
        .postImg {
         width: 80px;
         height: 80px;
         /deep/ .van-image__img {
          border-radius: 8px;
         }
        }
        .postDesc {
          width: 210px;
          font-size: 15px;
        }
        .postAmountBox {
          display: flex;
          align-items: flex-end;
          .goldIcon {
            width: 30px;
            height: 23.9px;
            margin-right: 12px;
          }
        }
      }
    }
  }
}
</style>
